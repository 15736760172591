<template>
  <ListHorizontal
    v-if="experts.length"
    base-slide-width="560"
    :title="t('Думка експертів')"
    :sub-title="props.subTitle"
    class="mt-10"
    :title-class="props.titleClass"
    :space-between="30"
    :slides="experts"
    slide-class="mb-5 p-4 border-2 border-gray bg-white"
    :slides-per-view="slidesPerView"
    :breakpoints="sliderBreakpoints"
  >
    <template #default="{ slide }">
      <div class="flex gap-2.5">
        <div class="h-15 min-w-[60px]">
          <nuxt-img
            width="130"
            height="130"
            :src="slide.author?.user?.avatar?.path || NO_IMAGE_PATH"
            class=""
            loading="lazy"
          />
        </div>
        <div class="flex flex-col flex-1 gap-3 text-xs">
          <div class="flex gap-2.5">
            <span class="font-semibold">{{ slide.author.firstName }}</span>
            <span class="ml-4">{{ slide.author?.shortDescription }}</span>
            <span class="ml-auto">{{ slide?.getDate() }}</span>
          </div>
          <div>
            <app-expandable-section
              :button-text="t('Показати все')"
              :button-text-expanded="t('Приховати')"
              button-wrapper-class="flex justify-center"
              expandable
              :collapsed-hight="80"
            >
              <span v-html="slide?.textWithLink(slide.comment)"></span>
            </app-expandable-section>
          </div>
          <div class="flex gap-2.5">
            <div
              v-if="slide.hasMedia()"
              class="flex justify-center gap-2 ml-2 flex-wrap"
            >
              <ListExpertsReviewMedia :slide="slide" />
            </div>
            <div class="flex gap-2.5 ml-auto">
              <a
                type="button"
                class="flex gap-1 items-center cursor-pointer"
                :class="
                  slide?.reacted === 'like'
                    ? 'text-green-ibis'
                    : 'text-gray-mud'
                "
                @click="toggleReaction('like', slide.id)"
              >
                <IconsReactionLike class="h-3.5 w-3.5" />
                <span>{{ slide.totalReactions.like }}</span>
              </a>
              <a
                type="button"
                class="flex gap-1 items-center cursor-pointer"
                :class="
                  slide?.reacted === 'dislike'
                    ? 'text-red-hot'
                    : 'text-gray-mud'
                "
                @click="toggleReaction('dislike', slide.id)"
              >
                <IconsReactionDislike class="h-3.5 w-3.5" />
                <span> {{ slide.totalReactions.dislike }} </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </template>
  </ListHorizontal>
</template>

<script setup>
import ProductFeedbackEntity from '~/entities/ProductFeedback.entity'
import { useAuthStore } from '~/stores/auth'

const { t } = useI18n()
const props = defineProps({
  title: {
    type: String,
    default: 'Думка експертів'
  },
  subTitle: {
    type: String,
    default: ''
  },
  titleClass: {
    type: String,
    default: 'text-xl font-bold'
  },
  slidesPerView: {
    type: Number,
    default: 1
  },
  sliderBreakpoints: {
    type: Object,
    default: () => ({})
  }
})

const product = inject('product')
const authStore = useAuthStore()

const { data: expertsReviews } = useLazyAsyncData(
  'product-experts-reviews-' + (product?.value?.code || 'all'),
  () => {
    const filter = {
      page: 1,
      itemsPerPage: 15,
      customer: 'expert',
      'order[createdAt]': 'desc'
    }
    if (product?.value) {
      filter.reviewSubject = product.value['@id']
    }
    return useApi().productReviews.getAll(filter)
  },
  {
    transform: res => res['hydra:member']
  }
)

const toggleReaction = async (reaction, id) => {
  if (!authStore.loggedIn) {
    return authStore.showAuthDialog(useRoute().fullPath)
  }

  const index = experts.value?.findIndex(review => review.id === id)

  if (index !== -1) {
    const review = experts?.value[index]
    const currentReaction = review.reacted
    if (currentReaction) {
      review.totalReactions[currentReaction]--
    }
    review.reacted = currentReaction === reaction ? '' : reaction
    if (review.reacted) {
      review.totalReactions[review.reacted]++
    }
  }

  await useApi().productReviewLike.toggleReaction({
    id,
    type: reaction
  })
}

const experts = computed(
  () => expertsReviews.value?.map(item => new ProductFeedbackEntity(item)) || []
)
</script>
