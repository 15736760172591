<template>
  <TransitionRoot as="template" :show="props.open">
    <Dialog
      as="div"
      class="relative w-full max-w-full z-10"
      @close="emits('close')"
    >
      <AppDialogsCommonBackdrop />

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div
          class="flex min-h-full justify-center text-center items-center p-0"
        >
          <AppDialogsCommonPanelTransition>
            <DialogPanel
              class="fixed transform overflow-hidden rounded-0 bg-white px-[10px] md:px-[30px] py-[26px] text-left transition-all h-full sm:max-h-[80vh] w-full sm:my-8 sm:w-[85vw] sm:max-w-screen-2xl sm:p-6 sm:relative sm:rounded-lg flex items-center justify-center object-contain"
            >
              <AppDialogsCommonCloseButton
                class="absolute right-5 top-5 z-10"
                @click="emits('close')"
              />
              <slot></slot>
            </DialogPanel>
          </AppDialogsCommonPanelTransition>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { Dialog, DialogPanel, TransitionRoot } from '@headlessui/vue'

const props = defineProps({
  open: {
    type: Boolean,
    default: false
  }
})
const emits = defineEmits(['close'])
</script>
