<template>
  <ProductSectionsCommonPreviewDialog
    :open="modelValue"
    @close="emits('update:modelValue', false)"
  >
    <ProductSectionsCommonPreviewSlider
      :allow-zoom="true"
      :images="images"
      :initial-slide="props.initialSlide"
      :show-larger-button="false"
      is-modal-slider
      original-path
      :show-thumbs="false"
      size="lg"
      class="modal-reviews-slider"
    />
  </ProductSectionsCommonPreviewDialog>
</template>

<script setup>
const props = defineProps({
  modelValue: {
    type: Boolean,
    default: () => false
  },
  media: {
    type: Array,
    default: () => []
  },
  id: {
    type: Number || String,
    default: () => ''
  },
  initialSlide: {
    type: Number,
    default: () => 0
  }
})
const emits = defineEmits(['update:modelValue'])

const filteredMedia = computed(() => {
  return props.media.filter(item => item.type === 'image')
})

const { data: images } = useLazyAsyncData(
  props.id + '-reviews-original-images',
  () => useAsyncResources(filteredMedia.value)
)
</script>

<style>
.modal-reviews-slider img {
}
</style>
