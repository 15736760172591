<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="24"
      height="24"
      rx="12"
      fill="white"
    />
    <path
      d="M16.5 11.1339C17.1667 11.5188 17.1667 12.4811 16.5 12.866L10.5 16.3301C9.8333 16.715 8.99997 16.2339 8.99997 15.4641L8.99997 8.53584C8.99997 7.76604 9.8333 7.28491 10.5 7.66981L16.5 11.1339Z"
      fill="#FF612F"
    />
  </svg>
</template>
