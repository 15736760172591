<template>
  <template v-for="(item, idx) in props.slide.getMedia()" :key="item">
    <nuxt-img
      v-if="item.type === 'image'"
      :src="item.path"
      format="webp"
      height="60"
      width="60"
      fit="contain"
      loading="lazy"
      class="cursor-pointer max-w-[60px] max-h-[80px] !object-contain"
      @click="openModal(idx)"
    />
  </template>
  <ProductSectionsDetailsReviewsImageModal
    :id="slide.id"
    v-model="showModal"
    :initial-slide="initialSlide"
    :media="props.slide.getMedia()"
  />
</template>

<script setup>
const props = defineProps({
  slide: {
    type: Object,
    default: () => {}
  }
})

const initialSlide = ref(0)
const showModal = ref(false)

const openModal = index => {
  showModal.value = true
  initialSlide.value = index
}
</script>
