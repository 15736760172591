<template>
  <div class="">
    <div
      ref="expandableBlock"
      :class="['text-[14px] mt-3 relative overflow-hidden']"
      :style="expanded ? '' : `max-height: ${props.collapsedHight}px`"
    >
      <slot></slot>
      <div
        v-if="!expanded && canExpand"
        class="bg-gradient-to-b from-transparent to-white absolute w-full bottom-0 left-0 z-1"
        :class="+props.collapsedHight < 100 ? 'h-8' : 'h-20'"
      ></div>
    </div>
    <div
      v-if="props.expandable"
      :class="['mt-2.5', buttonWrapperClass, canExpand ? '' : 'hidden']"
    >
      <a
        href="#"
        :class="[
          'link-underline gap-3 text-[14px] flex items-center font-light',
          buttonClass
        ]"
        @click.prevent.stop="expanded = !expanded"
      >
        <IconsDropdownChevron
          v-if="showChevron"
          :active="expanded"
          active-class="!text-gray-mud"
        />
        {{
          expanded && props.buttonTextExpanded
            ? props.buttonTextExpanded
            : props.buttonText
        }}
      </a>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  expandable: {
    type: Boolean,
    default: true
  },
  buttonText: {
    type: String,
    default: 'Показати повністю'
  },
  buttonTextExpanded: {
    type: String,
    default: ''
  },
  collapsedHight: {
    type: [String, Number],
    default: '100'
  },
  showChevron: {
    type: Boolean,
    default: true
  },
  buttonClass: {
    type: String,
    default: ''
  },
  buttonWrapperClass: {
    type: String,
    default: ''
  }
})
const expanded = ref(!props.expandable)

const expandableBlock = ref(null)

const canExpand = ref(props.expandable)

onMounted(() => {
  canExpand.value =
    expandableBlock.value.scrollHeight > parseInt(props.collapsedHight)
})
</script>
